import React from 'react';
import { Expense } from '../../models/expense';
import { useStore } from '../../stores/Store';
import EditExpense from './EditExpense';
import { getExpenseDeleteWarningMessage } from './ExpenseData';
import ExpenseDataDisplayCard from './ExpenseDataDisplayCard';

interface Props {
  expense: Expense;
}

const ExpenseCard = ({ expense }: Props) => {
  const { modalStore, expenseStore } = useStore();
  const { confirmAndDelete } = expenseStore;

  const handleDelete = () => {
    confirmAndDelete(expense.id, getExpenseDeleteWarningMessage(expense));
  };

  const editClicked = () => {
    modalStore.openModal(<EditExpense expense={expense} />);
  };

  return (
    <ExpenseDataDisplayCard
      amount={expense.amount}
      expenseDate={expense.expenseDate}
      expenseType={expense.expenseType?.typeName}
      description={expense.description}
      editClicked={editClicked}
      deleteClicked={handleDelete}
    />
  );
};

export default ExpenseCard;
