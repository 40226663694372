import { createContext, useContext } from 'react';
import CommonStore from './CommonStore';
import ExpenseStore from './ExpenseStore';
import ExpenseTypeStore from './ExpenseTypeStore';
import IncomeStore from './IncomeStore';
import IncomeTypeStore from './IncomeTypeStore';
import ModalStore from './ModalStore';
import PaymentMethodStore from './PaymentMethodStore';
import SummaryStore from './SummaryStore';
import UserStore from './UserStore';

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  expenseStore: ExpenseStore;
  expenseTypeStore: ExpenseTypeStore;
  incomeStore: IncomeStore;
  incomeTypeStore: IncomeTypeStore;
  paymentMethodStore: PaymentMethodStore;
  summaryStore: SummaryStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  expenseStore: new ExpenseStore(),
  expenseTypeStore: new ExpenseTypeStore(),
  incomeStore: new IncomeStore(),
  incomeTypeStore: new IncomeTypeStore(),
  paymentMethodStore: new PaymentMethodStore(),
  summaryStore: new SummaryStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
