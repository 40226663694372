import { Expense } from '../../models/expense';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ExpenseCard from '../../controls/expenses/ExpenseCard';
import {
  getExpenseDeleteWarningMessage,
  getExpenseDisplayFieldProps,
} from '../../controls/expenses/ExpenseData';

const ExpenseList = () => {
  const { expenseStore, expenseTypeStore, paymentMethodStore } = useStore();

  const getNewItem = () => {
    const expense = new Expense();
    return expense;
  };

  const columns = getExpenseDisplayFieldProps(
    expenseStore,
    expenseTypeStore,
    paymentMethodStore
  );

  return (
    <>
      <GenericList
        className='tablet or lower hidden'
        store={expenseStore}
        title='Expenses'
        otherTitle='Expense'
        columns={columns}
        getDeleteWarningMessage={getExpenseDeleteWarningMessage}
        getNewItem={getNewItem}
      />
      <Grid className='mobile tablet only'>
        {expenseStore.items.map((expense: Expense) => (
          <Grid.Row key={expense.id}>
            <Grid.Column>
              <ExpenseCard expense={expense} />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </>
  );
};

export default observer(ExpenseList);
