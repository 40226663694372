import { ExpenseType } from './expense-type';
import { PaymentMethod } from './payment-method';

export class Expense {
  id: number;
  expenseDate: Date;
  expenseTypeId?: number;
  expenseType?: ExpenseType;
  paymentMethodId?: number;
  paymentMethod?: PaymentMethod;
  amount?: number;
  description?: string;

  paymentMethodIdAuto?: boolean;
  amountAuto?: boolean;
  descriptionAuto?: boolean;

  constructor() {
    this.id = 0;
    this.expenseDate = new Date();
  }

  get canSetPaymentMethod() {
    if (this.paymentMethodIdAuto !== undefined) {
      return this.paymentMethodIdAuto;
    }
    return this.paymentMethodId === undefined;
  }

  get canSetAmount() {
    if (this.amountAuto !== undefined) {
      return this.amountAuto;
    }
    return this.amount === undefined;
  }

  get canSetDescription() {
    if (this.descriptionAuto !== undefined) {
      return this.descriptionAuto;
    }
    return this.description === undefined;
  }
}
