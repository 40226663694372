import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import MyCurrencyInput from '../../common/form/MyCurrencyInput';
import MyDateInput from '../../common/form/MyDateInput';
import MyTextArea from '../../common/form/MyTextArea';
import MyTextInput from '../../common/form/MyTextInput';
import AppSelectInput from '../../controls/AppSelectInput';
import LoadingComponent from '../../layout/LoadingComponent';
import { ModelBase } from '../../models/model-base';
import { useStore } from '../../stores/Store';
import { StoreBase } from '../../stores/StoreBase';
import { FieldProps } from './FieldProps';

interface Props<T extends ModelBase> {
  store: StoreBase<T>;
  item: T;
  title: string;
  fields: FieldProps[];
  result?: (success: boolean, error?: string) => void;
  editSubmited?: (item: T) => void;
}

const GenericEditForm = <T extends ModelBase>({
  store,
  item,
  title,
  fields,
  result,
  editSubmited,
}: Props<T>) => {
  const { modalStore } = useStore();
  const { createItem, updateItem } = store;
  const [key, setKey] = useState(1);

  const isCreate = !item.id;

  const yupData: any = {};

  for (const field of fields.filter((x) => x.required)) {
    yupData[field.propName] = Yup.string().required(
      `The ${field.placeholder} is required`
    );
  }

  const validationSchema = Yup.object(yupData);

  const selFields = fields.filter((x) => !!x.store).map((x) => x.store!);
  const loading = selFields.findIndex((x) => x.loading) >= 0;

  if (loading) {
    return <LoadingComponent />;
  }

  const handleFormSubmit = (submitItem: T) => {
    if (editSubmited) {
      editSubmited(submitItem);
      modalStore.closeModal();
    } else {
      if (isCreate) {
        createItem(submitItem)
          .then(() => {
            modalStore.closeModal();
            toast.success(`${title} succeeded`);
            if (result) {
              result(true);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            if (result) {
              result(false, error.message);
            }
          });
      } else {
        updateItem(submitItem)
          .then(() => {
            modalStore.closeModal();
            toast.success(`${title} succeeded`);
            if (result) {
              result(true);
            }
          })
          .catch((error) => {
            toast.error(error.message);
            if (result) {
              result(false, error.message);
            }
          });
      }
    }
  };

  return (
    <Formik
      key={key}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={item}
      onSubmit={(values) => handleFormSubmit(values)}
    >
      {({ handleSubmit, isValid, isSubmitting, dirty, values }) => (
        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
          <Card style={{ width: '100%' }}>
            <Card.Content>
              <Header as='h2' content={title} color='teal' textAlign='center' />
            </Card.Content>
            <Card.Content>
              {fields
                .filter((x) => !x.notVisibleInEdit)
                .map((x) => {
                  switch (x.dataType) {
                    case 'DateInput':
                      return (
                        <MyDateInput
                          key={x.propName}
                          placeholderText={x.placeholder}
                          name={x.propName}
                          dateFormat='MMMM d, yyyy'
                        />
                      );

                    case 'CurrencyInput':
                      return (
                        <MyCurrencyInput
                          key={x.propName}
                          placeholder={x.placeholder}
                          name={x.propName}
                          changeHandler={(data: number) => {
                            if (x.propName + 'Auto' in (item as any)) {
                              (item as any)[x.propName] = +data;
                              (item as any)[x.propName + 'Auto'] = false;
                            }
                          }}
                        />
                      );

                    case 'TextInput':
                    case 'NumberInput':
                    case 'EmailInput':
                    case 'TelephoneInput':
                    case 'ImageInput':
                    case 'MonthInput':
                    case 'WeekInput':
                    case 'UrlInput':
                      let type = 'text';
                      if (x.dataType === 'NumberInput') {
                        type = 'number';
                      } else if (x.dataType === 'TelephoneInput') {
                        type = 'tel';
                      } else if (x.dataType === 'EmailInput') {
                        type = 'email';
                      } else if (x.dataType === 'ImageInput') {
                        type = 'image';
                      } else if (x.dataType === 'MonthInput') {
                        type = 'month';
                      } else if (x.dataType === 'WeekInput') {
                        type = 'week';
                      } else if (x.dataType === 'UrlInput') {
                        type = 'url';
                      }
                      return (
                        <MyTextInput
                          key={x.propName}
                          placeholder={x.placeholder}
                          name={x.propName}
                          type={type}
                          changeHandler={(data: string) => {
                            if (x.propName + 'Auto' in (item as any)) {
                              if (type === 'number') {
                                (item as any)[x.propName] = +data;
                              } else {
                                (item as any)[x.propName] = data;
                              }
                              (item as any)[x.propName + 'Auto'] = false;
                            }
                          }}
                        />
                      );

                    case 'TextArea':
                      return (
                        <MyTextArea
                          key={x.propName}
                          rows={3}
                          placeholder={x.placeholder}
                          name={x.propName}
                          changeHandler={(data: string) => {
                            if (x.propName + 'Auto' in (item as any)) {
                              (item as any)[x.propName] = data;
                              (item as any)[x.propName + 'Auto'] = false;
                            }
                          }}
                        />
                      );

                    case 'SelectInput':
                      return (
                        <AppSelectInput
                          key={x.propName}
                          placeholder={x.placeholder}
                          name={x.propName}
                          store={x.store as any}
                          changeHandler={(id: number) => {
                            if (x.setDefaultsOnNew) {
                              (item as any)[x.propName] = id;
                              x.setDefaultsOnNew(item).then(() => {
                                setKey(key + 1); // need this to make react re-render the form with the new data
                              });
                            }
                            if (x.propName + 'Auto' in (item as any)) {
                              (item as any)[x.propName] = id;
                              (item as any)[x.propName + 'Auto'] = false;
                            }
                          }}
                        />
                      );
                  }
                  return null;
                })}
            </Card.Content>
            <Card.Content extra>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                floated='right'
                positive
                type='submit'
                content='Submit'
                loading={isSubmitting}
              />
              <Button
                floated='right'
                type='button'
                content='Cancel'
                onClick={() => modalStore.closeModal()}
              />
            </Card.Content>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default observer(GenericEditForm);
