import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import NavBar from './NavBar';
import SideBar from './SideBar';
import { Route, Switch } from 'react-router-dom';
import { useStore } from '../stores/Store';
import LoadingComponent from './LoadingComponent';
import { observer } from 'mobx-react-lite';
import ModalContainer from '../common/modals/ModalContainer';
import PrivateRoute from './PrivateRoute';
import NotFound from '../pages/errors/NotFound';
import HomePage from '../pages/home/HomePage';
import AccountsPage from '../pages/accounts/AccountsPage';
import ExpenseList from '../pages/expenses/ExpenseList';
import IncomeList from '../pages/incomes/IncomeList';
import ExpenseTypeList from '../pages/data-lists/expense-types/ExpenseTypeList';
import PaymentMethodList from '../pages/data-lists/payment-methods/PaymentMethodList';
import IncomeTypeList from '../pages/data-lists/income-types/IncomeTypeList';
import DuplicateExpenses from '../pages/duplicate-expenses/DuplicateExpenses';
import MonthlySummaryList from '../pages/summary/MonthlySummaryList';
import LastMonthSummary from '../pages/summary/LastMonthSummary';
import CurrentMonthSummary from '../pages/summary/CurrentMonthSummary';

function App() {
  // const location = useLocation();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded) {
    return <LoadingComponent content='Loading...' />;
  }

  return (
    <>
      <ToastContainer position='bottom-right' hideProgressBar />
      <ModalContainer />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <SideBar>
              <Container style={{ padding: '10px', scroll: 'auto' }}>
                <Switch>
                  <PrivateRoute exact path='/home' component={AccountsPage} />
                  <PrivateRoute
                    exact
                    path='/expenses'
                    component={ExpenseList}
                  />
                  <PrivateRoute exact path='/incomes' component={IncomeList} />
                  <PrivateRoute
                    exact
                    path='/expensetypes'
                    component={ExpenseTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/paymentmethods'
                    component={PaymentMethodList}
                  />
                  <PrivateRoute
                    exact
                    path='/incometypes'
                    component={IncomeTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/duplicateexpenses'
                    component={DuplicateExpenses}
                  />
                  {/* <PrivateRoute
                    exact
                    path='/autopayments'
                    component={AutoPaymentsPage}
                  /> */}
                  <PrivateRoute
                    exact
                    path='/monthlysummarylist'
                    component={MonthlySummaryList}
                  />
                  <PrivateRoute
                    exact
                    path='/currentmonthsummary'
                    component={CurrentMonthSummary}
                  />
                  <PrivateRoute
                    exact
                    path='/lastmonthsummary'
                    component={LastMonthSummary}
                  />
                  <PrivateRoute component={NotFound} />
                </Switch>
              </Container>
            </SideBar>
          </>
        )}
      />
    </>
  );
}

export default observer(App);
