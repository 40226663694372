import { makeAutoObservable, runInAction } from 'mobx';
import { MonthlySummaryDto } from '../models/monthly-summary-dto';
import agent from '../api/agent';

export default class SummaryStore {
  summaries: MonthlySummaryDto[] = [];
  currentMonthSummary?: MonthlySummaryDto;
  lastMonthSummary?: MonthlySummaryDto;
  loading: Boolean = false;
  lastLoadTime: Date = new Date(2020, 0, 1);

  constructor() {
    makeAutoObservable(this);
  }

  clear = () => {
    this.summaries.splice(0, this.summaries.length);
    this.currentMonthSummary = undefined;
    this.lastMonthSummary = undefined;
  };

  reloadSummaries = async () => {
    this.clear();
    this.loadSummaries();
  };

  loadSummaries = async () => {
    if (
      this.summaries.length === 0 ||
      Math.abs(Date.now() - this.lastLoadTime.getDate()) / 36e5 > 1
    ) {
      // Either never loaded, or been while since last load
      this.setLoading(true);
      this.clear();

      try {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const lastMonth = month === 0 ? 11 : month - 1;
        const serverList = await agent.Summary.get();
        const current = await agent.Summary.getForMonth(year, month);
        const last = await agent.Summary.getForMonth(year, lastMonth);

        runInAction(() => {
          this.currentMonthSummary = current;
          this.lastMonthSummary = last;
          serverList.forEach((x) => this.summaries.push(x));
        });
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.clear();
        });
      } finally {
        this.setLoading(false);
      }
    }
  };

  loadCurrentMonth = async () => {
    this.setLoading(true);
    this.lastMonthSummary = undefined;
    try {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const current = await agent.Summary.getForMonth(year, month + 1);

      runInAction(() => {
        this.currentMonthSummary = current;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.clear();
      });
    } finally {
      this.setLoading(false);
    }
  };

  loadLastMonth = async () => {
    this.setLoading(true);
    this.lastMonthSummary = undefined;
    try {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const lastMonth = month === 0 ? 11 : month - 1;
      const last = await agent.Summary.getForMonth(year, lastMonth + 1);

      runInAction(() => {
        this.lastMonthSummary = last;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.clear();
      });
    } finally {
      this.setLoading(false);
    }
  };

  setLoading = (value: Boolean) => {
    this.loading = value;
  };
}
