import { useEffect } from 'react';
import SummaryMonth from '../../controls/summary/SummaryMonth';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/Store';

const CurrentMonthSummary = () => {
  const { summaryStore } = useStore();
  const { currentMonthSummary } = summaryStore;

  useEffect(() => {
    summaryStore.loadCurrentMonth();
  }, [summaryStore]);

  if (summaryStore.loading) {
    return <LoadingComponent />;
  }

  if (
    !currentMonthSummary ||
    (currentMonthSummary.expenseTypeTotals.length === 0 &&
      currentMonthSummary.incomeTypeTotals.length === 0)
  ) {
    return (
      <h3 style={{ textAlign: 'center', paddingTop: 100 }}>
        There is no data available for the selected month.
      </h3>
    );
  }

  return <SummaryMonth monthlySummary={currentMonthSummary} />;
};

export default CurrentMonthSummary;
