import { Grid } from 'semantic-ui-react';
import { IncExpTypeTotalDto } from '../../models/inc-exp-type-total-dto';
import SummaryItemRow from './SummaryItemRow';
import SummaryTotalRow from './SummaryTotalRow';

interface Props {
  totalRowTitle: string;
  totalAmount: number;
  typeTotals?: IncExpTypeTotalDto[];
}

const SummaryGrid = ({ totalRowTitle, totalAmount, typeTotals }: Props) => {
  return (
    <Grid style={{ paddingLeft: '30px', paddingBottom: '20px' }}>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={10} computer={6}>
          <Grid stackable>
            {typeTotals?.map((exp) => (
              <SummaryItemRow key={exp.typeId} summaryRow={exp} />
            ))}
            <SummaryTotalRow title={totalRowTitle} totalAmount={totalAmount} />
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SummaryGrid;
