import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const AccountsPage = () => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='home' />
        Some stuff will come here someday 🤞😎
      </Header>
    </Segment>
  );
};

export default AccountsPage;
