import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Dropdown, Menu, Icon } from 'semantic-ui-react';
import { useStore } from '../stores/Store';
import CommonMenu from './CommonMenu';

const NavBar = () => {
  const {
    commonStore: { toggleSidebar },
    userStore: { user, logout },
  } = useStore();

  const hideOnSmall = 'tablet hidden mobile hidden';
  const showOnlyOnSmall = 'tablet only mobile only grid';

  return (
    <Menu inverted fixed='top'>
      <Container>
        <Menu.Item
          className={showOnlyOnSmall}
          onClick={() => {
            toggleSidebar();
          }}
        >
          <Icon name='sidebar' />
        </Menu.Item>
        <Menu.Item header exact as={NavLink} to='/home'>
          <img
            src='/assets/logo.png'
            alt='logo'
            style={{ marginRight: '10px' }}
          />
          Ashi Accounts
        </Menu.Item>
        <CommonMenu classes={hideOnSmall} hideSidebar={() => {}} />
        <Menu.Item className={hideOnSmall} position='right'>
          <Dropdown pointing='top left' text={user?.knownAs}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logout} text='Logout' icon='power' />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default observer(NavBar);
