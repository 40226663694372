import { Grid } from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';

interface Props {
  title: string;
  totalAmount: number;
}

const SummaryTotalRow = ({ title, totalAmount }: Props) => {
  return (
    <Grid.Row style={{ borderBottom: '4px double lightgray' }}>
      <Grid.Column width={10}>
        <h5>{title}</h5>
      </Grid.Column>
      <Grid.Column width={6} textAlign='right'>
        <h5>{currencyFormatter(totalAmount)}</h5>
      </Grid.Column>
    </Grid.Row>
  );
};

export default SummaryTotalRow;
