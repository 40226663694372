export interface CurrencyFormatOptions {
  significantDigits: number;
  thousandsSeparator: string;
  decimalSeparator: string;
  symbol: string;
}

const defaultOptions: CurrencyFormatOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: 'Rs ',
};

export const currencyFormatter = (
  value: number,
  options: CurrencyFormatOptions = defaultOptions
) => {
  if (typeof value !== 'number') value = 0.0;
  options = { ...defaultOptions, ...options };
  const displayValue = value.toFixed(options.significantDigits);

  const [currency, decimal] = displayValue.split('.');
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
};
