import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { Expense } from '../../models/expense';
import { FieldProps } from '../../pages/base/FieldProps';
import ExpenseStore from '../../stores/ExpenseStore';
import ExpenseTypeStore from '../../stores/ExpenseTypeStore';
import PaymentMethodStore from '../../stores/PaymentMethodStore';

export const getExpenseDisplayFieldProps = (
  expenseStore: ExpenseStore,
  expenseTypeStore: ExpenseTypeStore,
  paymentMethodStore: PaymentMethodStore
) => {
  const columns: FieldProps[] = [
    {
      dataType: 'DateInput',
      placeholder: 'Expense Date',
      title: 'Date',
      propName: 'expenseDate',
      required: true,
      requiredMsg: 'Expense date is required',
      sorted: 'descending',
    },
    {
      dataType: 'SelectInput',
      placeholder: 'Expense Type',
      title: 'Type',
      propName: 'expenseTypeId',
      displayPropName: 'expenseType.typeName',
      required: true,
      requiredMsg: 'Expense type is required',
      store: expenseTypeStore,
      setDefaultsOnNew: expenseStore.setDefaultsOnNew,
    },
    {
      dataType: 'SelectInput',
      placeholder: 'Payment Method',
      title: 'Payment Method',
      propName: 'paymentMethodId',
      displayPropName: 'paymentMethod.methodName',
      required: true,
      requiredMsg: 'Payment method is required',
      store: paymentMethodStore,
    },
    {
      dataType: 'CurrencyInput',
      placeholder: 'Amount',
      propName: 'amount',
      required: true,
      requiredMsg: 'Amount is required',
    },
    {
      dataType: 'TextArea',
      placeholder: 'Description',
      propName: 'description',
      required: false,
    },
  ];

  return columns;
};

export const getExpenseDeleteWarningMessage = (expense: Expense) => {
  return `Are you sure you want to delete the Expense ${
    expense.expenseType?.typeName
  } for ${currencyFormatter(expense.amount ?? 0)} ?`;
};
