import { observer } from 'mobx-react-lite';
import { Link, Redirect } from 'react-router-dom';
import { Button, Header, Image, Segment } from 'semantic-ui-react';
import { useStore } from '../../stores/Store';
import classes from './HomePage.module.css';
import LoginForm from '../users/LoginForm';

const HomePage = () => {
  const { userStore, modalStore } = useStore();

  if (userStore.isLoggedIn) {
    return <Redirect to='/home' />;
  }

  return (
    <Segment inverted textAlign='center' vertical className={classes.masthead}>
      <div style={{ width: '100%' }}>
        <Header as='h1' inverted>
          <Image
            size='massive'
            src='/assets/logo.png'
            alt='logo'
            style={{ marginBottom: 12 }}
          />
          Ashi Accounts
        </Header>
        {userStore.isLoggedIn ? (
          <>
            <Header as='h2' inverted content='Welcome to Ashi Accounts' />
            <Button as={Link} to='/home' size='huge' inverted>
              Go to Accounts
            </Button>
          </>
        ) : (
          <>
            <Button
              size='huge'
              inverted
              onClick={() => modalStore.openModal(<LoginForm />)}
            >
              Login
            </Button>
          </>
        )}
      </div>
    </Segment>
  );
};

export default observer(HomePage);
