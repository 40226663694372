import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { store } from '../stores/Store';
import { history } from '..';
import { PaginatedResult } from '../models/Pagination';
import { User, UserForLogin } from '../models/User';
import { ModelBase } from '../models/model-base';
import { Expense } from '../models/expense';
import { AutoPaymentDto, AutoPaymentMonthDto } from '../models/auto-payment';
import { MonthlySummaryDto } from '../models/monthly-summary-dto';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  // console.log(axios.defaults.baseURL);
  const token = store.commonStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === 'string') {
          toast.error(data);
        }
        if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
          history.push('/not-found');
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) modalStateErrors.push(data.errors[key]);
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        toast.error('Unauthorized');
        break;
      case 404:
        history.push('/not-found');
        break;
      case 500:
        store.commonStore.setServerError(data);
        // history.push('/server-error');
        break;
    }
    Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response?.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => request.get<User>('/auth'),
  login: (user: UserForLogin) => request.post<User>('/auth/login', user),
};

const GenericData = <T extends ModelBase>(url: string) => {
  return {
    list: () => request.get<T[]>('/' + url),
    listPaged: (params: URLSearchParams) =>
      axios.get<PaginatedResult<T[]>>('/' + url, { params }).then(responseBody),
    details: (id: number) => request.get<T>(`/${url}/${id}`),
    create: (item: T) => request.post<T>('/' + url, item),
    update: (item: T) => request.put<T>(`/${url}/${item.id}`, item),
    delete: (id: number) => request.del<boolean>(`/${url}/${id}`),
  };
};

const Expenses = {
  // getExpenseTypeDefaults: (id: number) =>
  //   request.get<Expense>(`/expenses/defaults/type/${id}`),
  getDuplicates: () => request.get<Expense[]>(`/expenses/duplicates`),
};

const AutoPayments = {
  getMonths: () => request.get<AutoPaymentMonthDto[]>(`/AutoPayments/months`),
  getForMonth: (year: number, month: number) =>
    request.get<AutoPaymentDto[]>(`/AutoPayments/${year}/${month}`),
  process: (items: AutoPaymentDto[]) =>
    request.post<boolean>(`/AutoPayments/payments`, items),
};

const Summary = {
  get: () => request.get<MonthlySummaryDto[]>(`/summary`),
  getForMonth: (year: number, month: number) =>
    request.get<MonthlySummaryDto>(`/summary/${year}/${month}`),
  getPdfForMonth: (year: number, month: number) =>
    axios.get(`/summary/pdf/${year}/${month}`, {
      responseType: 'blob',
    }),
};

const agent = {
  Account,
  Expenses,
  AutoPayments,
  Summary,
  GenericData,
};

export default agent;
