import React, { useEffect } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import LoadingComponent from '../../layout/LoadingComponent';
import { MonthlySummaryDto } from '../../models/monthly-summary-dto';
import { useStore } from '../../stores/Store';

const MonthlySummaryList = () => {
  const { summaryStore } = useStore();

  useEffect(() => {
    summaryStore.reloadSummaries();
  }, [summaryStore]);

  if (summaryStore.loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Button
        primary
        content='Reload'
        onClick={() => {
          summaryStore.reloadSummaries();
        }}
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Month</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Income</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Expenses</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>Savings</Table.HeaderCell>
            <Table.HeaderCell collapsing textAlign='center'>
              <Button
                icon
                onClick={() => {
                  summaryStore.reloadSummaries();
                }}
              >
                <Icon name='refresh' />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {summaryStore.summaries.map((summary: MonthlySummaryDto) => (
            <Table.Row key={summary.sort}>
              <Table.Cell>{summary.monthDisplay}</Table.Cell>
              <Table.Cell textAlign='right'>
                {currencyFormatter(summary.totalIncome)}
              </Table.Cell>
              <Table.Cell textAlign='right'>
                {currencyFormatter(summary.totalExpenses)}
              </Table.Cell>
              <Table.Cell
                textAlign='right'
                style={{
                  color:
                    summary.totalIncome - summary.totalExpenses < 0
                      ? 'red'
                      : 'green',
                }}
              >
                {currencyFormatter(summary.totalIncome - summary.totalExpenses)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Button content='Details' secondary size='tiny' />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default MonthlySummaryList;
