import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { Income } from '../../models/income';
import { FieldProps } from '../../pages/base/FieldProps';
import IncomeTypeStore from '../../stores/IncomeTypeStore';

export const getIncomeDisplayFieldProps = (
  incomeTypeStore: IncomeTypeStore
) => {
  const columns: FieldProps[] = [
    {
      dataType: 'DateInput',
      placeholder: 'Income Date',
      title: 'Date',
      propName: 'incomeDate',
      required: true,
      requiredMsg: 'Income date is required',
      sorted: 'descending',
    },
    {
      dataType: 'SelectInput',
      placeholder: 'Income Type',
      title: 'Type',
      propName: 'incomeTypeId',
      displayPropName: 'incomeType.incomeTypeValue',
      required: true,
      requiredMsg: 'Income type is required',
      store: incomeTypeStore,
    },
    {
      dataType: 'CurrencyInput',
      placeholder: 'Amount',
      propName: 'amount',
      required: true,
      requiredMsg: 'Amount is required',
    },
    {
      dataType: 'TextArea',
      placeholder: 'Description',
      propName: 'description',
      required: false,
    },
  ];

  return columns;
};

export const getIncomeDeleteWarningMessage = (income: Income) => {
  return `Are you sure you want to delete the Income of type ${
    income.incomeType?.incomeTypeValue
  } for ${currencyFormatter(income.amount ?? 0)} ?`;
};
