import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

interface Props {
  hideSidebar: () => void;
  classes: string;
}

const CommonMenu = ({ hideSidebar, classes }: Props) => {
  return (
    <>
      <Menu.Item
        name='Expenses'
        as={NavLink}
        to='/expenses'
        icon='credit card outline'
        className={classes}
        onClick={hideSidebar}
      />
      <Menu.Item
        name='Incomes'
        as={NavLink}
        to='/incomes'
        icon='money'
        className={classes}
        onClick={hideSidebar}
      />
      <Menu.Item className={classes}>
        <Dropdown pointing='top left' text='Actions'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={NavLink}
              to='/autopayments'
              text='Auto Payments'
              icon='setting'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/duplicateexpenses'
              text='Find Possible Duplicates'
              icon='copy'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/monthlysummarylist'
              text='Monthly Summaries'
              icon='list'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/currentmonthsummary'
              text='Current Month Summary'
              icon='file alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/lastmonthsummary'
              text='Last Month Summary'
              icon='file alternate outline'
              onClick={hideSidebar}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item className={classes}>
        <Dropdown pointing='top left' text='Other Data'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={NavLink}
              to='/expensetypes'
              text='Expense Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/incometypes'
              text='Income Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/paymentmethods'
              text='Payment Methods'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
};

export default CommonMenu;
