import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agent';
import { ModelBase } from '../models/model-base';
import { deleteWarningFunc } from '../common/form/DeleteWarning';

export class GenericStore {
  loading = false;
  lastLoadedAt?: Date;

  setDefaultsOnNew = async (newItem: any): Promise<boolean> => {
    console.log('setDefaultsOnNew');
    return new Promise((resolve) => {
      resolve(false);
    });
  };
}

export class StoreBase<T extends ModelBase> extends GenericStore {
  itemRegistry = new Map<number, T>();

  constructor(protected url: string, protected typeName = 'Item') {
    super();

    makeObservable(this, {
      itemRegistry: observable,
      loading: observable,
      lastLoadedAt: observable,
      items: computed,
      itemsForSelectList: computed,
      getItem: action,
      createItem: action,
      updateItem: action,
      loadItemsIfNeeded: action,
      deleteItem: action,
      setLoading: action,
      reloadItems: action,
    });
  }

  protected get agent() {
    return agent.GenericData<T>(this.url);
  }

  protected fetchItems = async () => {
    return await this.agent.list();
  };

  protected saveItem = async (item: T) => {
    if (item.id) {
      return await this.agent.update(item);
    } else {
      return await this.agent.create(item);
    }
  };

  protected sortItems = (items: T[]) => {
    return items;
  };

  protected getDisplayText = (item: T) => {
    return '';
  };

  protected loadItems = async () => {
    this.setLoading(true);
    try {
      runInAction(() => {
        this.itemRegistry.clear();
      });
      const items = await this.fetchItems();
      runInAction(() => {
        items.forEach((item) => {
          this.setItem(item);
        });
        this.lastLoadedAt = new Date();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  loadItemsIfNeeded = async () => {
    if (
      !this.lastLoadedAt ||
      new Date().getTime() - this.lastLoadedAt.getTime() > 60 * 60 * 1000
    ) {
      await this.loadItems();
    }
  };

  reloadItems = async () => {
    await this.loadItems();
  };

  get items() {
    const values = Array.from(this.itemRegistry.values());
    if (values.length === 0) {
      return [];
    }
    return this.sortItems(values);
  }

  getItem = (id: number) => {
    return this.itemRegistry.get(id);
  };

  protected setItem = (item: T) => {
    this.itemRegistry.set(item.id, item);
  };

  get itemsForSelectList() {
    const items = this.items;
    if (items && items.length > 0) {
      const retItems: { text: string; value: number }[] = [];
      items.forEach((x) =>
        retItems.push({ text: this.getDisplayText(x), value: x.id })
      );
      return retItems;
    } else {
      return [];
    }
  }

  createItem = async (item: T) => {
    try {
      this.setLoading(true);
      if (item.id > 0) {
        throw new Error('Error Creating Item. Item already has an id');
      }
      item = (await this.saveItem(item)) as T;
      if (item.id <= 0) {
        toast.error('Failed to save item. Id returned is invalid.');
      } else {
        runInAction(() => this.setItem(item));
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
    return item;
  };

  updateItem = async (item: T) => {
    try {
      this.setLoading(true);
      if (item.id <= 0) {
        throw new Error('Error Updating Item. Item has no id');
      }
      item = (await this.saveItem(item)) as T;
      if (item.id > 0) {
        runInAction(() => this.setItem(item));
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
    return item;
  };

  deleteItem = async (id: number) => {
    try {
      this.setLoading(true);
      if (await this.agent.delete(id)) {
        runInAction(() => this.itemRegistry.delete(id));
        return true;
      }
      return false;
    } catch (error) {
      toast.error('Failed to delete ' + this.typeName);
      toast.error(error.message);
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  confirmAndDelete = (id: number, message?: string) => {
    if (!message) {
      message = `Are you sure you want to delete the item with ID = ${id}`;
    }
    deleteWarningFunc(message, async () => {
      return await this.deleteItem(id);
    });
  };
}
