import { useState } from 'react';
import { Accordion, Button, Icon } from 'semantic-ui-react';
import { saveAs } from 'file-saver';
import agent from '../../api/agent';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { MonthlySummaryDto } from '../../models/monthly-summary-dto';
import SummaryGrid from './SummaryGrid';
import { toast } from 'react-toastify';

interface Props {
  monthlySummary: MonthlySummaryDto;
}

const SummaryMonth = ({ monthlySummary }: Props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [downloading, setDownloading] = useState(false);

  const totalIncome = monthlySummary.totalIncome ?? 0;
  const totalExpenses = monthlySummary.totalExpenses ?? 0;
  const totalSavings = totalIncome - totalExpenses;
  const savingsColor = totalSavings >= 0 ? 'green' : 'red';

  return (
    <div style={{ paddingBottom: 20 }}>
      <h2>Summary For {monthlySummary.monthDisplay}</h2>

      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={() => setActiveIndex(activeIndex !== 0 ? 0 : -1)}
        >
          <h3>
            <Icon name='dropdown' />
            Expenses
            <span style={{ paddingLeft: 20 }}>
              {currencyFormatter(totalExpenses)}
            </span>
          </h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <SummaryGrid
            totalRowTitle='Total Expenses'
            totalAmount={totalExpenses}
            typeTotals={monthlySummary.expenseTypeTotals}
          />
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={0}
          onClick={() => setActiveIndex(activeIndex !== 1 ? 1 : -1)}
        >
          <h3>
            <Icon name='dropdown' />
            Incomes
            <span style={{ paddingLeft: 20 }}>
              {currencyFormatter(totalIncome)}
            </span>
          </h3>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <SummaryGrid
            totalRowTitle='Total Income'
            totalAmount={totalIncome}
            typeTotals={monthlySummary.incomeTypeTotals}
          />
        </Accordion.Content>
      </Accordion>
      <h2>
        Savings:
        <span style={{ paddingLeft: 20, color: savingsColor }}>
          {currencyFormatter(totalSavings)}
        </span>
      </h2>
      <br />
      <Button
        content='Download PDF'
        primary
        loading={downloading}
        onClick={() => {
          setDownloading(true);
          agent.Summary.getPdfForMonth(
            monthlySummary.year,
            monthlySummary.month
          )
            .then((data) => {
              console.log(data.data);
              console.log(
                data.request.getResponseHeader('Content-Disposition')
              );
              let fileName = 'Summary.pdf';
              let parts: string[] = data.request
                .getResponseHeader('Content-Disposition')
                .split('; ');
              for (const part of parts) {
                if (part.startsWith('filename=')) {
                  fileName = part.substr('filename='.length);
                  break;
                }
              }
              saveAs(data.data, fileName);
            })
            .catch((reason) => {
              console.log(reason);
              toast.error(reason);
            })
            .finally(() => {
              setDownloading(false);
            });
        }}
      />
    </div>
  );
};

export default SummaryMonth;
