import { makeAutoObservable, runInAction } from 'mobx';
import { User, UserForLogin } from '../models/User';
import { store } from './Store';
import { history } from '..';
import agent from '../api/agent';

export default class UserStore {
  user?: User = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    // console.log('isLoggedIn', !!this.user);
    return !!this.user;
  }

  login = async (creds: UserForLogin) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => {
        this.user = user;
      });
      history.push('/home');
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    this.user = undefined;
    history.push('/');
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      runInAction(() => (this.user = user));
    } catch (error) {
      console.log(error);
    }
  };
}
