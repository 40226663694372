import { Grid } from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { IncExpTypeTotalDto } from '../../models/inc-exp-type-total-dto';

interface Props {
  summaryRow: IncExpTypeTotalDto;
}

const SummaryItemRow = ({ summaryRow }: Props) => {
  return (
    <Grid.Row style={{ borderBottom: '1px solid lightgray' }}>
      <Grid.Column width={10}>{summaryRow.typeName}</Grid.Column>
      <Grid.Column width={6} textAlign='right'>
        {currencyFormatter(summaryRow.total)}
      </Grid.Column>
    </Grid.Row>
  );
};

export default SummaryItemRow;
